@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--hubspot-lp {
  --screen-height: 100vh;
  --font-size: calc(var(--screen-height) * 0.05);
  --video-height: calc(var(--screen-height) * 0.97);
  --spacing: calc(var(--screen-height) * 0.035);
}

.hubspot-lp__heading {
  margin-bottom: calc(var(--spacing) / 1.9);
  font-size: var(--font-size);

  h1 {
    margin-bottom: calc(var(--spacing) / 1.9);

    @include media-breakpoint-up(lg) {
      font-size: clamp(2.375rem, 6.2vh, 4.875rem);
    }

    &.h2 {
      font-size: 1.5rem;
      line-height: (36 / 32);

      @include media-breakpoint-up(lg) {
        font-size: clamp(1.5rem, 4.5vh, 2.9375rem);
      }
    }
  }

  @include media-breakpoint-down(md) {
    h1 {
      br {
        display: none;
      }
    }
  }
}

.hubspot-lp__middle {
  @include media-breakpoint-down(lg) {
    margin-bottom: var(--spacing);
  }

  @include media-breakpoint-down(xl) {
    @media (orientation: portrait) {
      margin-bottom: var(--spacing);
    }
  }

  .btn {
    margin-top: var(--spacing);

    @include media-breakpoint-up(lg) {
      border-radius: 34px;
      font-size: calc(var(--font-size) * 0.6);
    }

    @include media-breakpoint-down(md) {
      margin-top: var(--spacing);
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    .btn.btn--arrow {
      padding-right: 88px;
      padding-left: 48px;

      &::after {
        right: 35px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzNiAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMyLjA2MDcgMTcuODM1OEMzMi42NDY0IDE3LjI1IDMyLjY0NjQgMTYuMzAwMyAzMi4wNjA3IDE1LjcxNDVMMjIuNTE0NyA2LjE2ODU0QzIxLjkyODkgNS41ODI3NiAyMC45NzkyIDUuNTgyNzYgMjAuMzkzNCA2LjE2ODU0QzE5LjgwNzYgNi43NTQzMyAxOS44MDc2IDcuNzA0MDggMjAuMzkzNCA4LjI4OTg2TDI4Ljg3ODcgMTYuNzc1MUwyMC4zOTM0IDI1LjI2MDRDMTkuODA3NiAyNS44NDYyIDE5LjgwNzYgMjYuNzk2IDIwLjM5MzQgMjcuMzgxN0MyMC45NzkyIDI3Ljk2NzUgMjEuOTI4OSAyNy45Njc1IDIyLjUxNDcgMjcuMzgxN0wzMi4wNjA3IDE3LjgzNThaTTMxIDE1LjI3NTFMNCAxNS4yNzUxTDQgMTguMjc1MUwzMSAxOC4yNzUxTDMxIDE1LjI3NTFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
      }

      &:hover {
        &::after {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzNiAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMyLjA2MDcgMTcuODM1OEMzMi42NDY0IDE3LjI1IDMyLjY0NjQgMTYuMzAwMyAzMi4wNjA3IDE1LjcxNDVMMjIuNTE0NyA2LjE2ODU0QzIxLjkyODkgNS41ODI3NiAyMC45NzkyIDUuNTgyNzYgMjAuMzkzNCA2LjE2ODU0QzE5LjgwNzYgNi43NTQzMyAxOS44MDc2IDcuNzA0MDggMjAuMzkzNCA4LjI4OTg2TDI4Ljg3ODcgMTYuNzc1MUwyMC4zOTM0IDI1LjI2MDRDMTkuODA3NiAyNS44NDYyIDE5LjgwNzYgMjYuNzk2IDIwLjM5MzQgMjcuMzgxN0MyMC45NzkyIDI3Ljk2NzUgMjEuOTI4OSAyNy45Njc1IDIyLjUxNDcgMjcuMzgxN0wzMi4wNjA3IDE3LjgzNThaTTMxIDE1LjI3NTFMNCAxNS4yNzUxTDQgMTguMjc1MUwzMSAxOC4yNzUxTDMxIDE1LjI3NTFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
        }
      }
    }
  }
}

.hubspot-lp-video {
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
  width: clamp(31.25rem, var(--video-height), 73.75rem);
  border: 2px solid #fff;
  border-radius: 2.5rem;

  @include media-breakpoint-down(md) {
    border-radius: 1.75rem;
  }

  &__video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA2IiBoZWlnaHQ9IjEwNiIgdmlld0JveD0iMCAwIDEwNiAxMDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjUzIiBjeT0iNTMiIHI9IjUzIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNNzggNTAuNzY3OUM3OS4zMzMzIDUxLjUzNzcgNzkuMzMzMyA1My40NjIyIDc4IDU0LjIzMjFMMzguMjUgNzcuMTgxN0MzNi45MTY3IDc3Ljk1MTUgMzUuMjUgNzYuOTg5MyAzNS4yNSA3NS40NDk3TDM1LjI1IDI5LjU1MDNDMzUuMjUgMjguMDEwNyAzNi45MTY3IDI3LjA0ODUgMzguMjUgMjcuODE4M0w3OCA1MC43Njc5WiIgZmlsbD0iIzVENDJENiIvPgo8L3N2Zz4K');
    background-position: center center;
    background-size: 7%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: opacity 0.25s ease-in-out;
    transform: translate(-50%, -50%);
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      background-size: 53px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.hubspot-lp__bottom {
  img {
    @include media-breakpoint-up(md) {
      margin-bottom: 28px;
    }

    @include media-breakpoint-down(md) {
      max-width: 50px;
    }
  }

  p.h3 {
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
      line-height: 1.25;
    }

    @include media-breakpoint-down(md) {
      br {
        display: none;
      }
    }
  }
}

.hubspot-lp-footer {
  margin: 32px 0.625rem 0.625rem;
  padding: 72px 0 54px;
  border-radius: 20px;
  background-color: $black;

  @include media-breakpoint-down(md) {
    padding: 40px 0;
  }
}

.hubspot-lp-footer__top {
  margin-bottom: 144px;

  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }
}

@include color-mode(dark) {
  .hubspot-lp-footer {
    margin: 0;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
    }
  }

  .hubspot-lp-footer__top .section--partners-slider {
    padding: 0;
    background-color: transparent !important;
  }
}

.hubspot-lp-footer__bottom {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: end;
    justify-content: space-between;
    column-gap: 16px;

    p {
      margin: 0;

      &:nth-child(1) {
        order: 0;
      }

      &:nth-child(2) {
        order: 2;
        text-align: left !important;
      }

      &:nth-child(3) {
        order: 1;
      }
    }
  }

  img {
    @include media-breakpoint-down(lg) {
      margin-bottom: 46px;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;
      max-width: 125px;
    }
  }

  .text--big {
    margin-top: 24px;
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }
}

.section--hubspot-lp {
  .section--partners-slider {
    margin-bottom: 6rem !important;
    background-color: #000;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px !important;
    }
  }

  .section--case-studies {
    padding-top: 0;

    .case-studies-slider.is-grid {
      margin-bottom: 0;
    }
  }
}

.hubspot-lp__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: calc(4.5rem + var(--spacing));

  @include media-breakpoint-up(lg) {
    @media (orientation: landscape) {
      padding-bottom: var(--spacing);
      min-height: var(--screen-height);
    }
  }
}
